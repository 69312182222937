window.onload = () => {
	blendInElemenents();
	getCorrectSliderHeight();
};

window.onresize = () => {
	getCorrectSliderHeight();
};

const getCorrectSliderHeight = () => {
	const bigImageSliderStage = document.querySelector(".big-image-slider-stage");

	if (bigImageSliderStage) {
		const sliderImageSize = document
			.querySelector(".images-container img")
			.getBoundingClientRect();
		bigImageSliderStage.style.height = `${Math.floor(
			sliderImageSize.height
		)}px`;
	}
};

const blendInElemenents = () => {
	const windowHeight = window.innerHeight / 2 + 200;
	let elements = document.querySelectorAll(".animate"),
		i;

	for (i = 0; i < elements.length; ++i) {
		if (elements[i].getBoundingClientRect().top < windowHeight) {
			elements[i].classList.add("blend-in");
		}
	}

	window.addEventListener("scroll", function () {
		for (i = 0; i < elements.length; ++i) {
			if (elements[i].getBoundingClientRect().top < windowHeight) {
				elements[i].classList.add("blend-in");
			}
		}
	});
};

// Mobile Navigation
const navigationIcon = document.querySelector(".hamburger");
const mobileNavigationMenu = document.querySelector(".navigation-items");
const mobileContactLink = document.querySelector("#mobile-contact-link");

navigationIcon.addEventListener("click", () => {
	document.body.style.overflow = "hidden";
	navigationIcon.classList.toggle("close");
	mobileNavigationMenu.classList.toggle("open");
});

mobileContactLink.addEventListener("click", () => {
	document.body.style.overflow = "visible";
	navigationIcon.classList.toggle("close");
	mobileNavigationMenu.classList.toggle("open");
	setTimeout(() => {
		window.scroll({
			top: document.body.scrollHeight,
			left: 0,
			behavior: "smooth",
		});
	}, 500);
});

// Scroll to bottom when clicking contact link
const getDesktopContactNavigiationLink = document.querySelector(
	"#desktop-contact-link"
);

getDesktopContactNavigiationLink.addEventListener("click", function () {
	window.scroll({
		top: document.body.scrollHeight,
		left: 0,
		behavior: "smooth",
	});
});

// Set correct year for copyright information
const copyrightYearContainer = document.querySelector("#copyright-year");
const date = new Date();
const year = date.getFullYear();
copyrightYearContainer.innerHTML = year;

// Functionallity for big stage slider
const forwardsButton = document.querySelector(".forwards");
const backwardsButton = document.querySelector(".backwards");
const sliderImages = document.querySelectorAll(".images-container img");
let sliderCounter = 0;

if (sliderImages.length !== 0) {
	const autoSlide = setInterval(() => {
		sliderCounter++;
		if (sliderCounter >= sliderImages.length) {
			sliderCounter = 0;
		}
		if (sliderCounter === 0) {
			sliderImages[0].style.opacity = 1;
			setTimeout(() => {
				sliderImages[1].style.opacity = 1;
				sliderImages[2].style.opacity = 1;
			}, 300);
		}
		if (sliderCounter === 1) {
			sliderImages[0].style.opacity = 0;
		}
		if (sliderCounter === 2) {
			sliderImages[1].style.opacity = 0;
		}
		if (sliderCounter === 3) {
			sliderImages[2].style.opacity = 0;
		}
		if (sliderCounter === 4) {
			sliderImages[3].style.opacity = 0;
		}
	}, 5000);

	forwardsButton.addEventListener("click", () => {
		clearInterval(autoSlide);
		sliderCounter++;
		if (sliderCounter === 0) {
			sliderImages[0].style.opacity = 1;
			setTimeout(() => {
				sliderImages[1].style.opacity = 1;
				sliderImages[2].style.opacity = 1;
			}, 300);
		}
		if (sliderCounter === -1) {
			sliderImages[2].style.opacity = 0;
		}
		if (sliderCounter === -2) {
			sliderImages[1].style.opacity = 0;
		}
		if (sliderCounter >= sliderImages.length) {
			sliderCounter = 0;
			sliderImages[0].style.opacity = 1;
			setTimeout(() => {
				sliderImages[1].style.opacity = 1;
				sliderImages[2].style.opacity = 1;
			}, 500);
		}
		if (sliderCounter === 1) {
			sliderImages[0].style.opacity = 0;
		}
		if (sliderCounter === 2) {
			sliderImages[1].style.opacity = 0;
		}
		if (sliderCounter === 3) {
			sliderImages[2].style.opacity = 0;
		}
		if (sliderCounter === 4) {
			sliderImages[3].style.opacity = 0;
		}
	});

	backwardsButton.addEventListener("click", () => {
		clearInterval(autoSlide);
		sliderCounter--;
		if (sliderCounter === 0) {
			sliderImages[0].style.opacity = 1;
			setTimeout(() => {
				sliderImages[1].style.opacity = 1;
				sliderImages[2].style.opacity = 1;
			}, 300);
		}
		if (sliderCounter === 1) {
			sliderImages[1].style.opacity = 1;
		}
		if (sliderCounter === 2) {
			sliderImages[2].style.opacity = 1;
		}
		if (sliderCounter <= -sliderImages.length) {
			sliderCounter = 0;
			sliderImages[0].style.opacity = 1;
			setTimeout(() => {
				sliderImages[1].style.opacity = 1;
				sliderImages[2].style.opacity = 1;
			}, 500);
		}
		if (sliderCounter === -1) {
			sliderImages[1].style.opacity = 0;
			sliderImages[2].style.opacity = 0;
			setTimeout(() => {
				sliderImages[0].style.opacity = 0;
			}, 50);
		}
		if (sliderCounter === -2) {
			sliderImages[2].style.opacity = 1;
		}
		if (sliderCounter === -3) {
			sliderImages[1].style.opacity = 1;
		}
		if (sliderCounter === -4) {
			sliderImages[0].style.opacity = 1;
		}
	});
}
